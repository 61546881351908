export function encode(tardySlip) {
	const stringObj = JSON.stringify(tardySlip)
	const buffer = new Buffer.from(stringObj, 'utf-8')
	return buffer.toString('base64')
}

export function decode(tardyString) {
	const buffer = new Buffer.from(tardyString, 'base64')
	const stringObj = buffer.toString('utf-8')
	return JSON.parse(stringObj)
}
