import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { decode } from './lib/util'
import TardySlip from './components/TardySlip'

const App = () => (
    <Switch>
        <Route exact path="/" render={() => <TardySlip />} />
        <Route path="/for/:user/:reason" render={({match}) => {
            const tardySlip = decode(match.params.reason)
            return (<TardySlip
                student={match.params.user}
                date={tardySlip.date}
                time={tardySlip.time}
                reason={tardySlip.reason}
                signature={tardySlip.signature}
                isExcused={tardySlip.isExcused}
            />)
        }} />
        <Redirect to="/" />
    </Switch>
)

ReactDOM.render(<BrowserRouter><App /></BrowserRouter>, document.getElementById('app'))
