import React, {useState, useRef} from 'react'
import copy from 'copy-to-clipboard'
import { encode } from '../lib/util'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const TardySlip = ({
    date,
    time,
    student,
    reason,
    signature,
    isExcused
}) => {
    const [slipDate, setSlipDate] = useState(date)
    const [slipTime, setSlipTime] = useState(time)
    const [slipStudent, setSlipStudent] = useState(student)
    const [slipReason, setSlipReason] = useState(reason)
    const [slipSignature, setSlipSignature] = useState(signature)
    const [excused, setExcused] = useState(typeof isExcused === 'boolean' ? isExcused : null)
    const [unexcused, setUnexcused] = useState(typeof isExcused === 'boolean' ? !isExcused : null)

    const clearable = !!(slipDate || slipTime || slipStudent || slipReason || slipSignature || [typeof excused, typeof unexcused].includes('boolean'))
    const componentRef = useRef()

    const handleCheckbox = (type) => {
        if (type === 'excused') {
            setExcused(!excused)
            if ((!excused || unexcused) && !excused === unexcused) {
                setUnexcused(!!excused)
            }
        } else {
            setUnexcused(!unexcused)
            if ((excused || !unexcused) && excused === !unexcused) {
                setExcused(!!unexcused)
            }
        }
    }

    const handleCopy = () => {
        copy(createShareLink())
        toast('Copied!')
    }

    const handleClear = () => {
        setSlipDate(""),
        setSlipTime(""),
        setSlipStudent(""),
        setSlipReason(""),
        setSlipSignature(""),
        setExcused(null),
        setUnexcused(null)
    }

    const createShareLink = () => {
        const tardySlip = {
            date: slipDate,
            time: slipTime,
            reason: slipReason,
            signature: slipSignature,
            isExcused: excused || (unexcused ? !unexcused : null)
        }
        return `https://tardyslip.me/for/${slipStudent}/${encode(tardySlip)}`
    }

    return (
        <>
            <div className="tardySlip" ref={componentRef}>
                <h1>Tardy Slip</h1>
                <div className="row date-time">
                    <span><b>Date:</b></span>
                    <textarea spellCheck="false" onChange={(e) => setSlipDate(e.target.value)} value={slipDate} />
                    <span><b>Time:</b></span>
                    <textarea spellCheck="false" onChange={(e) => setSlipTime(e.target.value)} value={slipTime} />
                </div>
                <div className="row">
                    <span><b>Student:</b></span>
                    <textarea spellCheck="false" onChange={(e) => setSlipStudent(e.target.value)} value={slipStudent} />
                </div>
                <div className="row reason-label">
                    <span className="reason"><b>Reason:</b></span>
                </div>
                <div className="row reason">
                    <textarea spellCheck="false" name="reason" rows="3" onChange={(e) => setSlipReason(e.target.value)} value={slipReason} />
                </div>

                <h2>Admit Student To Class</h2>
                <div className="row excused">
                    <span className="checkbox__input">
                        <input type="checkbox" checked={!!excused} onClick={() => handleCheckbox("excused")} onChange={() => {}}/>
                        <span className="checkbox__control" onClick={() => handleCheckbox("excused")}>
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' aria-hidden="true" focusable="false">
                                <path fill='none' stroke='currentColor' strokeWidth='3' d='M1.73 12.91l6.37 6.37L22.79 4.59' />
                            </svg>
                        </span>
                    </span>
                    <span><b> Excused</b></span>
                </div>
                <div className="row unexcused">
                    <span className="checkbox__input">
                        <input type="checkbox" checked={!!unexcused} onClick={() => handleCheckbox("unexcused")} onChange={() => {}}/>
                        <span className="checkbox__control" onClick={() => handleCheckbox("unexcused")}>
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' aria-hidden="true" focusable="false">
                                <path fill='none' stroke='currentColor' strokeWidth='3' d='M1.73 12.91l6.37 6.37L22.79 4.59' />
                            </svg>
                        </span>
                    </span>
                    <span><b> Unexcused</b></span>
                </div>
                <div className="row signature">
                    <span className="spacer"></span>
                    <textarea spellCheck="false" onChange={(e) => setSlipSignature(e.target.value)} value={slipSignature} />
                </div>
                <div className="row signature-label">
                    <span className="spacer"></span>
                    <span className="label">Principal / School Authority</span>
                </div>
                <div className="row final">
                    <div className="footer"><b>0304-BUN:</b> (0615-0620) Form 6-5: Solomita & Sons Printing Co.</div>
                </div>
            </div>
            {(clearable || !!slipStudent) && (
                <div className="actions">
                    <button onClick={() => handleClear()}>Clear</button>
                    <button hidden={!slipStudent} onClick={() => handleCopy()}>Copy Tardy Slip Link</button>
                </div>
            )}
            <footer>Made with 💜 by <a href="https://twitter.com/curryshoe" target="_blank">Curryshoe</a></footer>
            <ToastContainer />
        </>
    )
}

export default TardySlip